
.menu:not(.el-menu--collapse) {
  width: 200px;
}

.menu {
  height: 100%;
  background: #fff;
}
.active-menu {
  background-color: #ebf5ff;
  @apply border-primary-dark border-r-4;
}
