
.side-menu {
  height: calc(100vh - 60px);
  overflow: scroll;
}
.home {
  height: calc(100vh - 60px);
}
.main-page {
  padding-top: 48px;
}
