
.nav-toggle {
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.nav-toggle-on {
  transform: rotate(-180deg);
}
.logo {
  border-radius: 12px;
  transition: transform 0.1s cubic-bezier(0, 1.8, 1, 1.8);
}
.logo:hover {
  transform: translateY(5px) rotate(-2deg) scale(1.15);
}
